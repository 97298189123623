import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, tap } from "rxjs";

// --- Models ---
import { Credentials } from "src/app/models/credentials";
import { Email, Password, LoginSuccessful } from "src/app/models/conta";
import { AuthStore } from "src/app/stores/auth/auth-store";

export interface IDataTokens {
	token?: string;
	refreshToken?: string;
}

@Injectable()
export class AuthService {
	constructor(private http: HttpClient, private authStore: AuthStore) {}

	login(credentials: Credentials): Observable<LoginSuccessful> {
		return this.http.post<LoginSuccessful>(`/api/auth/login`, credentials);
	}

	refreshToken(data: IDataTokens): Observable<LoginSuccessful> {
		return this.http.put<LoginSuccessful>(`/api/auth/refresh`, data).pipe(
			tap((response: LoginSuccessful) => {
				let auth = {...response, isAuthenticated: true}
				this.authStore.setAuth(auth);
			})
		);
	}

	solicitarRecuperacao(email: Email): Observable<Email> {
		return this.http.post<Email>(`/api/auth/send-email`, email);
	}

	redefinirSenha(password: Password): Observable<Password> {
		return this.http.post<Password>(`/api/auth/password_token`, password);
	}

	loginMaster(credentials: Credentials): Observable<LoginSuccessful> {
		return this.http.post<LoginSuccessful>(
			`/api/master/auth/login`,
			credentials
		);
	}
}
