import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	CanActivate,
	Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthStore } from "../stores/auth/auth-store";

@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authStore: AuthStore) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
		return this.authStore.isAuthenticated$.pipe(
			tap((isAuthenticated) => {
				if (!isAuthenticated)
					this.router.navigate(["auth"], {
						queryParams: {
							returnUrl: state.url,
						},
					});
			})
		);
	}
}
