import { BrowserModule, DomSanitizer } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";

import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from "ngx-ui-loader";

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AuthService } from "./services/auth/auth.service";
import { AuthStore } from "./stores/auth/auth-store";
import { HttpClientModule } from "@angular/common/http";
import { AuthGuard } from "./guards/auth.guard";

import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";

registerLocaleData(localePt);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,

		AppRoutingModule,
		NgxUiLoaderModule.forRoot({
			minTime: 1,
			fgsColor: "'#03ACB2",
			fgsType: "circle",
			pbColor: "#03ACB2",
			bgsOpacity: 0.5,
			bgsType: "ball-spin-clockwise",
			blur: 2,
			fgsSize: 90,
			logoUrl: "",
			pbDirection: "ltr",
			pbThickness: 3,
		}),
		NgxUiLoaderRouterModule,
	],
	providers: [
		AuthService,
		AuthStore,
		AuthGuard,
		{ provide: LOCALE_ID, useValue: "pt" },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon(
      'whatsapp',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/whatsapp-svg.svg')
    );
		matIconRegistry.addSvgIcon(
      'linkedin',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/linkedin-svg.svg')
    );
  }
}
