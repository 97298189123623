import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
	{
		path: "",
		loadChildren: () =>
			import("./shell/shell.module").then((m) => m.ShellModule),
		canActivate: [AuthGuard],
	},
	{
		path: "auth",
		loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: "auth/suporte",
		loadChildren: () =>
			import("./master/auth/auth-master.component.module").then(
				(m) => m.AuthMasterModule
			),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
