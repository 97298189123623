import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, distinctUntilChanged } from "rxjs/operators";

import { Usuario } from "src/app/models/usuario";
import { IDataTokens } from "src/app/services/auth/auth.service";

export interface Auth {
	habilitarPropostas?: boolean;
	token?: string;
	refreshToken?: string;
	usuario?: Usuario;
	isAuthenticated: boolean;
}

@Injectable()
export class AuthStore {
	private _auth = new BehaviorSubject<Auth>({
		isAuthenticated: false,
	});

	public readonly auth$ = this._auth
		.asObservable()
		.pipe(
			distinctUntilChanged(
				(prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
			)
		);

	isAuthenticated$: Observable<boolean> = this.auth$.pipe(
		map((state) => state.isAuthenticated)
	);

	constructor(private router: Router) {
		const token = localStorage.getItem("token");
		const refreshToken = localStorage.getItem("refreshToken");
		const userJson = localStorage.getItem("user");

		if (token && userJson) {
			try {
				this.setAuth({
					token,
					refreshToken,
					usuario: JSON.parse(userJson),
					isAuthenticated: true,
				});
			} catch (e) {
				console.error(e);
			}
		}
	}

	setAuth(auth: Auth) {
		let currentAuth = {
			...auth,
			usuario: { habilitarPropostas: auth.habilitarPropostas, ...auth.usuario },
		};
		localStorage.setItem("token", auth.token || "");
		localStorage.setItem("refreshToken", auth.refreshToken || "");
		localStorage.setItem("user", JSON.stringify(currentAuth.usuario));
		this._auth.next(currentAuth);
	}

	setIsAuthenticated(isAuthenticated: boolean) {
		const state = this._auth.value;
		this._auth.next({
			...state,
			isAuthenticated,
		});
	}

	clearAuth() {
		const state = this._auth.value;
		const isMaster = !state.usuario?.tenantId;

		this._auth.next({
			...state,
			usuario: null,
			token: null,
			isAuthenticated: false,
		});

		localStorage.removeItem("token");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("user");

		if (isMaster) {
			this.router.navigate(["auth/suporte/login"]);
		} else {
			window.location.reload();
		}
	}

	getAuth() {
		return JSON.parse(localStorage.getItem("user") || "{}");
	}
}
